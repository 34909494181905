<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('hall.default[2]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <div v-if="pageState==0">
      <div class="header0">
          <div class="MyHeader2">
              <img class="head" :src="`./static/head/${UserInfo.header}`" />
          </div>
          <div>{{ UserInfo.username.substring(0,15) }}</div>
      </div>
    <div class="infoBox" style="line-height: 25px;color: #8CBFC0">
      <div>
        <div style="font-size:15px;color: #e7e7e7">{{ UserInfo.username.substring(0,15) }}</div>
        <div>{{$t('bindAccount.fields[0]')}} :{{CardInfo.name}}</div>
      </div>
      <div>
        <div style="font-size:15px"> USDT{{ $t("hall.default[1]") }}:
            <span style="color: #DCA787">{{ parseFloat(UserInfo.balance).toFixed(2)}}</span></div>
        <div>{{$t('buy.label[2]')}} :{{CardInfo.card_no}}</div>
      </div>
    </div>
      <div class="infoBox">
          <div style="display: flex;padding: 15px">
              <div style="line-height: 25px;width: 50%">
                  <div style="font-size: 25px;color: #DAA78A;font-weight: bold">{{parseFloat(CoinInfo.price).toFixed(2)}}</div>
                  <div>{{$t('sell.label[3]')}}</div>
                  <div style="color: #86C0C1">USDT</div>
              </div>
              <div style="line-height: 25px;width: 50%">
                  <div style="font-size: 25px;font-weight: bold">{{parseFloat(CoinInfo.bestPrice).toFixed(2)}}</div>
                  <div>{{$t('sell.label[4]')}}</div>
                  <div style="color: #86C0C1">USDT</div>
              </div>
          </div>
          <div>
          <div style="display: flex;background-color: #337F7B">
            <van-field required
                       class="input" :border="false"
                       v-model="BankInfo.price"
                       :label="$t('buy.label[5]')"
                       :placeholder="$t('buy.placeholder[0]')"
            />
            <van-button  color="#DAA78A" size="min" style="width: 140px" @click="setPrice">
              <span style="color:#FFF;font-size: 14px">{{$t('buy.label[7]')}}</span>
            </van-button>
          </div>
              <div class="line11"></div>
          <div style="display: flex">
            <van-field required
                       class="input" :border="false"
                       v-model="BankInfo.total_number"
                       :label="$t('buy.label[6]')"
                       :placeholder="$t('buy.placeholder[1]')"
            />
          </div>
              <div class="line11"></div>
          <div style="display: flex">
            <van-field required readonly
                       class="input"
                       v-model="counttimestr"
                       @click="showPicker = true"
                       :label="$t('buy3[0]')"
                       :placeholder="$t('buy.placeholder[1]')"
            />
          </div>
              <div class="line11"></div>
          <van-popup v-model:show="showPicker" round position="bottom">
            <van-picker show-toolbar :title="$t('buy3[0]')" :confirm-button-text="$t('common[2]')"
                        :cancel-button-text="$t('common[3]')"
                    :columns="columns"
                    @cancel="showPicker = false"
                    @confirm="onConfirm"
            >
                <div class="tool" slot="toolbar">
                </div>
            </van-picker>
          </van-popup>
          <div style="display: flex">
            <van-field required readonly
                       class="input"
                       v-model="ordertypestr"
                       @click="showPicker2 = true"
                       :label="$t('buy3[2]')"
                       :placeholder="$t('buy.placeholder[1]')"
            />
          </div>
              <div class="line11"></div>
          <van-popup v-model:show="showPicker2" round position="bottom">
            <van-picker show-toolbar :title="$t('buy2[0]')" :confirm-button-text="$t('common[2]')"
                        :cancel-button-text="$t('common[3]')"
                    :columns="columntypes"
                    @cancel="showPicker2 = false"
                    @confirm="onConfirmType"
            />
          </van-popup>
          <div style="display: flex" v-if="BankInfo.ordertype==0">
            <van-field
                    class="input" :border="false"
                    v-model="BankInfo.target_uid"
                    :label="$t('buy3[7]')"
                    :placeholder="$t('buy3[8]')"
            />
          </div>
          <div style="display: flex" v-if="BankInfo.ordertype==1">
            <van-field
                    class="input" :border="false"
                    v-model="BankInfo.count_limit"
                    :label="$t('buy3[5]')"
                    :placeholder="$t('buy3[6]')"
            />
          </div>
              <div class="line11"></div>
          <div style="display: flex;background-color: #252D30">
            <van-field required :border="false" class="input"
                       v-model="BankInfo.min_number"
                       :label="$t('buy.label[10]')"
                       :placeholder="$t('buy.placeholder[2]')"
            />
            <van-field required border class="input"
                       v-model="BankInfo.max_number"
                       :placeholder="$t('buy.placeholder[3]')"
            />
          </div>
          </div>
      </div>
      <div style="text-align: center;margin-top: 20px">
        <p class="btn" @click="onSubmit">{{ $t('buy.label[9]') }}</p>
      </div>
    </div>
      <div class="completePanel" v-else>
          <div>
              <img src="../../public/static/icon/btn_done.png" style="width:50px"/>
          </div>
          <div style="color: #e7e7e7;font-size: 22px">{{$t('buy2[1]')}}</div>
          <div>{{BankInfo.total_number}}{{$t('buy2[2]')}}</div>
          <div style="margin: 10px auto;">
              <van-button block color="#DAA78A" to="/hall">
                  <span style="font-size: 18px">{{ $t('sell4[5]') }}</span>
              </van-button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BindAccount',
    components: {

    },
    props: [],
    data() {
      return {
        tabsActive: 0,
        weixinAcc: '',
        pageState:0,
        showUsdtType: false,
        showPicker:false,
        showPicker2:false,
        CardInfo:{},
        isVertical:true,
        robotList:[],
        counttimes:[2,6,12,24,72,500,2000],
        counttimestr:'',
        ordertypestr:'',
        BankInfo:{price:null,total_number:null,min_number:null,max_number:null,counttime:2,ordertype:0,count_limit:null,target_uid:null},
        CoinInfo:{price:0,bestPrice:0},
        columns : [],
        columntypes:[],
        douyinImg: [],
        kuaishouImg: [],
        accountArr: [],
        tabsList: [],
      }
    },
    computed: {
    },
    watch: {

    },
    created() {
      this.$Model.GetUserInfo()
      this.$Model.GetBankCardList(data=>{
        this.CardInfo = data.data[0];
      });

      this.counttimes.forEach(tt=>{
        var ctime = tt+" "+this.$t("buy3[1]");
        this.columns.push(ctime);
      });
      this.counttimestr = this.columns[0];
      this.BankInfo.counttime = this.counttimes[0];

      this.ordertypestr = this.$t("buy3[3]");
      this.columntypes.push(this.$t("buy3[3]"))
      this.columntypes.push(this.$t("buy3[4]"))

      let usertype = this.UserInfo.user_type;
      if (!usertype||usertype==0){
        this.$Dialog.Toast(this.$t("busAuth2[5]"));
        this.$router.push("/hall");
        return;
      }

      this.usdtinfo = this.InitData.usdtinfo;
      var pump = this.UserInfo.pump;
      this.CoinInfo['price'] = this.usdtinfo ['price'];
      this.CoinInfo['bestPrice'] = this.usdtinfo ['price']*(1+pump);

      this.$parent.navBarTitle = this.$t('bindAccount.default[0]')

      this.$Model.GetRobotTasks({},(data) => {
        // debugger
        if (data['code']==1){
          this.robotList = data['info']
        }
      });


      // this.tabsList = this.InitData.taskclasslist.filter(item=>item.bind_status==1&&item.state==1)
      // this.accountArr = this.tabsList.flatMap(item=>item.bind_type==2?[this.UserInfo[item.bind_field]?[{url:this.ApiUrl+this.UserInfo[item.bind_field]}]:[]]:(this.UserInfo[item.bind_field]||''))
    },
    mounted() {

    },
    activated() {

    },
    destroyed() {

    },
    methods: {
      setPrice(){
        this.BankInfo.price = parseFloat(this.CoinInfo.bestPrice).toFixed(2);
      },
      setAmount(){
        this.BankInfo.total_number=this.UserInfo.balance;
      },
      onSubmit() {
        var bb = parseInt(this.BankInfo.min_number)>parseInt(this.BankInfo.max_number);
        if (this.BankInfo.price<=0){
          this.$Dialog.Toast(this.$t('mytask2[9]'));
          return;
        } else if (this.BankInfo.total_number<=0){
          this.$Dialog.Toast(this.$t('mytask2[7]'));
          return;
        } else if (this.BankInfo.min_number<=0||this.BankInfo.max_number<=0||bb){
          this.$Dialog.Toast(this.$t('mytask2[10]'));
          return;
        }
        this.BankInfo.name = this.CardInfo['name'];
        this.BankInfo.card_no = this.CardInfo['card_no'];
        this.BankInfo.task_type = 1;
        this.$Model.SubmitTask(
                this.BankInfo,
                (data) => {
                  if (data==-1){
                    this.$Dialog.Toast(this.$t('taskOrder2[3]'));
                    return;
                  }
                  console.log("buy: "+JSON.stringify(data));
                  let msg = '';
                  switch (data.code){
                    case 1:
                      msg = this.$t('common3[0]');
                      break;
                    case -1:
                      msg = this.$t('mytask2[7]');
                      break;
                    case -2:
                      msg = this.$t('mytask2[8]');
                      break;
                    case -3:
                      msg = this.$t('mytask2[9]');
                      break;
                    default:
                      msg = data.code_dec;
                      break;
                  }
                  this.$Dialog.Toast(msg);
                  if (data.code==1){
                      this.pageState = 1
                  }
                }
        );
      },
    onConfirm(obj,index){
        console.log(index);
        this.counttimestr = this.columns[index];
        this.showPicker = false;
        this.BankInfo.counttime = this.counttimes[index]
    },
      onConfirmType(obj,index){
        console.log(index);
        this.ordertypestr = obj;
        this.showPicker2 = false;
        this.BankInfo.ordertype = index;
      },
    }
  }
</script>
<style scoped>
  .PageBox {
    color: #588686;
    background-color: #0F4F4E;
  }

  .van-nav-bar {
    background-color: #0F4F4E;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#FFFEFF;
  }
  .van-nav-bar>>>.van-nav-bar__arrow {
    color:#FFFEFF;
  }


  .van-cell.input >>> .van-field__control{
border-bottom: 1px solid #8ac0c0;
  }

  .van-cell.input2 >>> .van-field__control{
    background-color: #8ac0c0;
      border-radius: 8px;
      color: #e7e7e7;
  }

  .completePanel{
      font-size: 17px;
      margin:20px auto;width:80%;text-align: center;line-height: 30px;margin-top: 40px;
  }

  .van-cell>>>.van-cell__title{
    font-size: 14px;
  }
  .van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image,.van-uploader>>>.van-uploader__preview{
    margin: 0;
    background-color: #f7f8fa;
    border-radius: 8px;
  }
  .header0 {
      display:flex;
      font-size: 17px;
      color: #588686;
      padding: 10px;
      padding-left: 15px;
      line-height: 50px;
      margin: 0px auto;
  }

  .MyHeader2 {
      font-size: 19px;
      border-radius: 50%;
      background: pink;
      overflow: hidden;
      width: 42px;
      height: 42px;
      margin-right: 10px;
  }
  .MyHeader2 img {
      width: 100%;
  }

  .panelHeader {
    margin-top: 0px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    color: #cccccc;
    width: 40%;
    text-align: center;
    margin-bottom: 10px;
  }

  .van-cell >>> .van-field__control {
    color: #8CBFC0 !important;
    padding-left: 7px;
    height: 100%;
  }
  .van-cell {
    background: #337F7B;
  }
  .line11 {
      height: 1px;
      margin: 0px auto;
      background-color: #0F4F4E;
      width: 95%;
      text-align: center;
      margin-left: 10px;
  }
  .van-cell >>> .van-field__control::-webkit-input-placeholder {
    color: #8CBFC0;
    font-family: Alibaba;
  }

  .van-cell >>> .van-field__control::-moz-placeholder {
    color: #8CBFC0;
    font-family: Alibaba;
  }

  .PageBox .van-cell>>>.van-cell__title{
    font-size: 15px;
    font-weight: bold;
    color: #e7e7e7;
  }
  .van-popup {
    background-color: #4e51bf;
  }

  .hallswipe {
    width: 96%;
    margin-left: 6px;
    padding: 15px;
    height: 60px;
    z-index: 50;
    margin-top: 5px;
    margin-bottom: 5px;
    color:#cccccc;
    background-color: #337F7B;
  }

  .robotheader{
    font-size: 19px;
    border-radius: 50%;
    background: pink;
    overflow: hidden;
    width: 32px;
    height: 32px;
  }
  .infoBox {
    width: 95%;
    margin-left: 10px;
    border-radius: 5px;
    background-color: #337F7B;
    padding: 10px;
    color: #e7e7e7;
    margin-bottom: 10px;
  }

  .btn {
    padding: 10px 50px;
    border-radius: 5px;
    background-color: #DAA78A;
    color: #FFFEFF;
    font-size: 18px;
    text-align: center;
    margin: 15px 15px 15px;
  }
</style>
